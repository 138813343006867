import * as React from "react"
import styled from "styled-components"
import Layout from "../layouts"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { Link } from "gatsby"
import {
  Section,
  SectionPageTitle,
  SectionDescription,
  Container,
} from "../components/Section"
import { StaticImage } from "gatsby-plugin-image"

const SectionMedia = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 30px;
`
const BgVectorGraphics = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  .gatsby-image-wrapper {
    display: block;
  }
`
const BtnToolbar = styled.div`
  text-align: center;
  > a {
    display: inline-block;
  }
`

const ThankyouPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Thank You" description="Thank You" />
    <Section
      xpt="300px"
      mpt="200px"
      pt="150px"
      xpb="280px"
      mpb="200px"
      pb="100px"
      bgColor="#F0F8FF"
    >
      <BgVectorGraphics>
        <StaticImage
          src="../images/vecteezy-backgrond.jpg"
          placeholder="blurred"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="not-found-bg"
        />
      </BgVectorGraphics>
      <Container pl="3%" pr="3%" maxWidth="960px">
        <SectionMedia>
          <StaticImage
            src="../images/thankyou.png"
            placeholder="blurred"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="coming-soon"
          />
        </SectionMedia>
        <SectionPageTitle textAlign="center" mb="10px">
          Thank you
        </SectionPageTitle>
        <SectionDescription textAlign="center">
          <p>
            For your interest in Liberty Metal Buildings our metal building
            experts will provide you a professional and written recommendation
            with a detailed quote for your upcoming project!
          </p>
        </SectionDescription>
        <BtnToolbar>
          <Link to="/">
            <PrimaryButton text="Go To Homepage" size="lg" />
          </Link>
        </BtnToolbar>
      </Container>
    </Section>
  </Layout>
)

export default ThankyouPage
